import React, { FC, useCallback, useEffect, useState } from "react"
import { PageProps } from "gatsby";
import { FixedSizeList as List } from 'react-window';

import Box from "../components/box"
import Search from "../components/form/search"
import Heading from "../components/heading"
import SEO from "../components/seo"
import Text from "../components/text"
import Wrapper from "../containers/wrapper"
import { useAllPokemon } from "../hooks/useAllPokemon"
import Button from "../components/button"
import PokedexPreview from "../components/dex/pokedex-preview"
import { outerHeight } from "../helpers/helpers";


interface Props extends PageProps {}

const Pokedex: FC<Props> = () => {
    const pokemon = useAllPokemon()
    const [height, setHeight] = useState(300)
    const listRef = React.createRef<typeof FixedSizeList>();
    const headerRef = React.createRef<HTMLDivElement>();
    const filterRef = React.createRef<HTMLDivElement>();
    const wrapperRef = React.createRef<HTMLDivElement>();
    const releasedPokemon = pokemon.filter(mon => mon.node.released)

    const getHeightRemaining = useCallback(() => {
        // Header 61, Padding 40, Footer 51
        const viewportHeight = window.innerHeight
        return viewportHeight + 15 - (61 + 40 + 51 + outerHeight(headerRef.current) + outerHeight(filterRef.current))
    }, [headerRef, filterRef    ])

    useEffect(() => {
        setHeight(getHeightRemaining())
    }, [])

    const getRow = ({ index, style }: any) => {
        return <div style={style}>
            <PokedexPreview pokemon={releasedPokemon[index].node} />
        </div>
    }

    return (
        <>
            <Wrapper>
                <SEO title={`Pokemon Go Pokédex`} />
                <Box ref={headerRef}>
                    <Search placeholder="Search" mb={4} />
                    {/* <Button variant="secondary">Shiny available</Button> */}
                </Box>
            </Wrapper>

            <Box ref={filterRef} mt={3} mb={2} position="sticky" top="95px" backgroundColor="grey.0" zIndex="100">
                <Wrapper>
                    <Box display="flex" flexWrap="wrap" justifyContent="space-between">
                        <Text py={4} fontSize={2} textAlign="center" m={0} onClick={() => listRef.current.scrollToItem(0, 'start')}>Kanto</Text>
                        <Text py={4} fontSize={2} textAlign="center" m={0} onClick={() => listRef.current.scrollToItem(151, 'start')}>Johto</Text>
                        <Text py={4} fontSize={2} textAlign="center" m={0} onClick={() => listRef.current.scrollToItem(251, 'start')}>Hoenn</Text>
                        <Text py={4} fontSize={2} textAlign="center" m={0} onClick={() => listRef.current.scrollToItem(385, 'start')}>Sinnoh</Text>
                        <Text py={4} fontSize={2} textAlign="center" m={0}>Unova</Text>
                        <Text py={4} fontSize={2} textAlign="center" m={0}>Kalos</Text>
                    </Box>
                </Wrapper>
            </Box>

            <Wrapper ref={wrapperRef}>
                <List
                    ref={listRef}
                    height={height}
                    itemCount={releasedPokemon.length}
                    itemSize={134}
                    width="100%"
                    overscanCount={10}
                >
                    {getRow}
                </List>
            </Wrapper>
        </>
    )
}

export default Pokedex
