import React, { FC } from "react"
import Img from 'gatsby-image'

import Card from "../card"
import Box from "../box"
import Text from "../text"
import Heading from "../heading"
import TypeTag from "../pokemon/type-tag"
import { pokemonNumberString, PokemonType } from "../../helpers/types"
import { Pokemon, PokemonForm, PokemonFormInfo } from "../../models/pokemon"
import Sparkle from "../../images/icons/sparkle"

interface Props {
    pokemon: Pokemon
}

const PokedexPreview: FC<Props> = ({ pokemon }) => {
    let pokemonForm: PokemonFormInfo | undefined
    let image
    try {
        const {forms} = pokemon
        const filteredForms = Object.entries(forms).filter(([_, info]) => info && !!info.types).filter(([form, _]) => form === PokemonForm.Normal || form === PokemonForm.Therian || form === PokemonForm.Origin ).map(([_, info]) => info)
        if (filteredForms && filteredForms.length > 0) {
            pokemonForm = filteredForms[0]
            image = pokemonForm.image ? pokemonForm.image.fluid : null
        }
    } catch(e) {
        console.error(e, pokemon.name)
    }

    return (
        <Card
            p={4}
            mb={3}
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            flexBasis="100%"
            position="relative"
        // backgroundColor={pokemon.type.length ? lightenDarkenColor(getTypeColor(pokemon.type[0]), -18) : ""}
        >
            {pokemon.shinyAvailable && <Box position="absolute" top="7px" right="10px"><Sparkle height="14px" fill="black" /></Box>}
            <Box>
                <Text
                    fontSize={3}
                    mb={4}
                    lineHeight="1"
                    color="grey.4"
                // color={lightenDarkenColor(getTypeColor(pokemon.type[0]), -80)}
                >
                    No. {pokemonNumberString(pokemon.pokemonId)}
                </Text>
                <Heading as="h2" fontSize={7} mb={2} color="black">{pokemon.name}</Heading>
                <Box display="flex">
                    {pokemonForm && pokemonForm.types && pokemonForm.types.map((type: string, index: number) => (
                        <TypeTag
                            key={`${pokemon.name}-type-${index}`}
                            type={type as PokemonType}
                            mr={2} />
                    ))}
                </Box>
            </Box>
            <Box width="100px">
                {image && <Img style={{ transformOrigin: 'center right', transform: 'scale(1.35) translateY(-10px)' }} fluid={image} />}
            </Box>
        </Card>
    )
}

export default PokedexPreview