import styled from "styled-components";
import { space, SpaceProps } from "styled-system";
import Input from "./input";

interface Props extends SpaceProps { }

const Search = styled(Input).attrs<Props>(props => ({
    type: 'search'
}))<Props>`
    &[type="search"] {   
        appearance: none;
        border-radius: 20px;
        width: 100%;
        display: block;
        border: 1px solid ${({ theme }) => theme.colors.grey[2]};
        color: ${({ theme }) => theme.colors.black};

        background-color: ${({ theme }) => theme.colors.white};
        outline: none;
        padding: 6px ${({ theme }) => theme.space[5]}px;
        font-size: ${({ theme }) => theme.fontSizes[4]}px;
        line-height: 1;
        box-shadow: none;

        ${space}
    }
`

export default Search